import React from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "./Modal.css";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__close" onClick={onClose}>
          <IoIosCloseCircleOutline className="modal__close__icon" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
