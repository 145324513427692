import { useLocation } from "react-router-dom";
import "./service.css";
import Header from "../../components/layouts/header/Header";
import Footer from "../../components/layouts/footer/Footer";
import { GiStrong, GiWantedReward } from "react-icons/gi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { SERVICE_DATA } from "../../data/serviceData";

function Service() {
  const location = useLocation();
  const state = location.state;

  const service = SERVICE_DATA.find((service) => service?.type === state?.type);

  return (
    <div>
      <Header />
      <div className="service">
        <section className="service__section__one">
          <h1>{service?.title}</h1>
        </section>
        <section className="service__section__two">
          <p>{service?.overview}</p>
        </section>
        <section className="service__section__three">
          <button className="tonal__buttons" style={{ width: "max-content" }}>
            <GiStrong />
            Reliable
          </button>
          <h2>Services provided</h2>
          <div className="service__section__three__wrap">
            {service?.services.map((service, index) => (
              <div>
                <h2>{service.title}</h2>
                <p>{service.description && service.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="service__section__four">
          <button className="tonal__buttons" style={{ width: "max-content" }}>
            <GiWantedReward />
            Benefits
          </button>
          <h2>
            Serum Health provides countless value to our patients through
            reliable and consistent service delivery
          </h2>
          <div className="service__section__four__wrap">
            {service?.benefits.map((benefit, index) => (
              <div>
                <IoIosCheckmarkCircle className="service__section__four__wrap__icon" />
                <p>{benefit}</p>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Service;
