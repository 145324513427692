import "./header.css";
import logo from "../../../assets/icons/SERUM NEW LOGO.png";
import logo2 from "../../../assets/icons/SERUM WHITE LOGO.png";
import { CiMenuFries } from "react-icons/ci";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import emailjs, { init } from "@emailjs/browser";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from "../../ui/modal/Modal";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOPen] = useState(false);
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    service: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleModalClick = () => {
    setIsOPen(!isOpen);
  };

  const closeModal = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      location: "",
      service: "",
    });
    setIsOPen(false);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setFormStatus({ ...formStatus, submitting: true });

    try {
      setFormStatus({ ...formStatus, submitting: true });

      //Email JS send form logic
      const data = formData;

      emailjs
        .send("service_gv8dt8j", "template_84syenr", data, "fL4UraYJiFZYYrz6z")
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        location: "",
        service: "",
      });
      setFormStatus({ submitting: false, submitted: true });
      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 15000);
    } catch (error) {
      console.error("Error sending form:", error);
      setFormStatus({ submitting: false, submitted: true });
    }
  };

  const [active, setActive] = useState(false);
  const handleSidebar = () => {
    setActive(!active);
  };

  return (
    <div className="header">
      <img
        src={logo}
        alt="logo"
        className="header__logo"
        onClick={() => navigate("/")}
      />
      <ul className="header__nav">
        <li
          className={
            location.pathname === "/about" ? "header__nav__active" : ""
          }
          onClick={() => navigate("/about")}
        >
          About us
        </li>

        {/* <li
          className={location.pathname === "" ? "header__nav__active" : ""}
          onClick={() => navigate("#faq")}
        >
          FAQ
        </li> */}
        <li
          className={
            location.pathname === "/services" ? "header__nav__active" : ""
          }
          onClick={() => {
            navigate("/services");
            setActive(!active);
          }}
        >
          Services
        </li>
        <li
          className={
            location.pathname === "/immunization" ? "header__nav__active" : ""
          }
          onClick={() => navigate("/immunization")}
        >
          Immunization
        </li>
        <li
          className={
            location.pathname === "/community" ? "header__nav__active" : ""
          }
          onClick={() => navigate("/community")}
        >
          Community
        </li>
      </ul>
      <span className="header__acc">
        <a
          href="tel:+2348167237000"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <button>Call now</button>
        </a>

        <button onClick={handleModalClick}>Book appointment</button>
        <BiMenu className={`header__menu`} onClick={handleSidebar} />
      </span>

      {/* SIDE BAR ELEMENTS */}
      <div className={`sidebar${active ? "--active" : ""}`}>
        <span className="sidebar__header">
          <img src={logo} alt="logo" className="header__logo" />
          <button onClick={handleSidebar}>Close</button>
        </span>
        <ul className="sidebar__nav">
          <li
            className={
              location.pathname === "/about" ? "header__nav__active" : ""
            }
            onClick={() => {
              navigate("/about");
              setActive(!active);
            }}
          >
            About us
          </li>

          {/* <li
            className={
              location.pathname === "/#faq" ? "header__nav__active" : ""
            }
            onClick={() => {
              navigate("/#faq");
              setActive(!active);
            }}
          >
            FAQ
          </li> */}
          <li
            className={
              location.pathname === "/services" ? "header__nav__active" : ""
            }
            onClick={() => {
              navigate("/services");
              setActive(!active);
            }}
          >
            Services
          </li>
          <li
            className={
              location.pathname === "/immunization" ? "header__nav__active" : ""
            }
            onClick={() => {
              navigate("/immunization");
              setActive(!active);
            }}
          >
            Immunization
          </li>
          <li
            className={
              location.pathname === "/community" ? "header__nav__active" : ""
            }
            onClick={() => {
              navigate("/community");
              setActive(!active);
            }}
          >
            Community
          </li>
        </ul>
        <span className="sidebar__acc">
          {" "}
          <a
            href="tel:+2348167237000"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <button>Call now</button>
          </a>
          <button onClick={handleModalClick}>Book appointment</button>
        </span>
      </div>

      {/* FORM MODAL */}
      <Modal isOpen={isOpen} onClose={closeModal}>
        <div className="modal__form">
          <img alt="logo" src={logo} />
          <h1>Complete this form to book an appointment</h1>
          <form onSubmit={handleOnSubmit} className="modal__form__container">
            <label htmlFor="firstName">First Name</label>
            <input
              onChange={handleOnChange}
              name="firstName"
              type="text"
              id="firstName"
              required
              value={formData.firstName}
            />

            <label htmlFor="lastName">Last Name:</label>
            <input
              onChange={handleOnChange}
              name="lastName"
              type="text"
              id="lastName"
              required
              value={formData.lastName}
            />

            <label htmlFor="email">Email</label>
            <input
              onChange={handleOnChange}
              name="email"
              type="email"
              id="email"
              required
              value={formData.email}
            />

            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              onChange={handleOnChange}
              name="phoneNumber"
              type="number"
              id="phoneNumber"
              required
              value={formData.phoneNumber}
            />

            <label htmlFor="location">Location</label>
            <select
              onChange={handleOnChange}
              name="location"
              required
              value={formData.location}
            >
              <option value="">--</option>
              <option value="Abuja">Abuja</option>
              <option value="Lagos">Lagos</option>
            </select>

            <label htmlFor="service">Service</label>
            <select
              onChange={handleOnChange}
              name="service"
              required
              value={formData.service}
            >
              <option value="">--</option>
              <option value="Antenatal care">Antenatal care</option>
              <option value="Postpartum care">Postpartum care</option>
              <option value="Home care">Home care</option>
              <option value="Immunization">Immunization</option>
              <option value="Chronic disease care">Chronic disease care</option>
              <option value="Supplements">Supplements</option>
            </select>
            {formStatus.submitted && (
              <div className="modal__form__message">
                Application successful! You willl receive a follow up email or
                call.
              </div>
            )}

            <span>
              <button type="button" onClick={closeModal}>
                Cancel form
              </button>
              <button type="submit" disabled={formStatus.submitting}>
                {formStatus.submitting ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  "Submit form"
                )}
              </button>
            </span>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
