import Footer from "../../components/layouts/footer/Footer";
import Header from "../../components/layouts/header/Header";
import image1 from "../../assets/images/Simplifying the Healthcare Experience.png";
import "./about.css";
import { BiTargetLock } from "react-icons/bi";

function About() {
  return (
    <div>
      <Header />
      <div>
        <div className="about__section__one">
          <h1>Transforming health care door to door</h1>
          <p>
            Serum Health provides distributed, at-home care tailored to the
            needs of mothers, newborns, the elderly, and the sick. Our services
            range from comprehensive maternity health care to nursing and
            chronic disease management, all delivered in the comfort of your
            home.
          </p>
        </div>
        <div className="about__section__two">
          <div className="about__section__two__start">
            <button className="tonal__buttons" style={{ width: "max-content" }}>
              <BiTargetLock /> Our mission
            </button>
            <h1>
              We’re simplifying the healthcare experience by removing the
              complexity from being healthy and well.
            </h1>
            <p>
              Experience a revolutionary shift in healthcare with our approach
              that eliminates unnecessary complexity and streamlines your path
              to health and well-being. We are dedicated to providing you with
              clear, actionable solutions and support, ensuring that every
              aspect of your journey towards a healthier life is straightforward
              and accessible. Our goal is to simplify every step, so you can
              focus on what truly matters—your health and happiness.
            </p>
          </div>
          <div className="about__section__two__end">
            <img alt="display" src={image1} />
          </div>
        </div>
        <div className="about__section__three">
          <h1>Our story is you</h1>
          <p>
            At the heart of our journey lies your unique experience and personal
            growth. Every step we take is inspired by your aspirations,
            challenges, and successes. Your story shapes our mission, and
            together, we create a narrative of progress, connection, and shared
            achievemen
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
