import "./footer.css";
import logo from "../../../assets/icons/SERUM NEW LOGO.png";
import { BiArrowToTop } from "react-icons/bi";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="footer">
      <div className="footer__header">
        <img src={logo} alt="logo" className="header__logo" />
        <span>
          <h1 onClick={backToTop}>
            Back to top <BiArrowToTop />
          </h1>
          <p>&copy; Serum Health 2024</p>
        </span>
      </div>
      <div className="footer__body">
        <ul className="footer__body__nav">
          <li onClick={() => navigate("/about")}>About us</li>

          {/* <li onClick={() => navigate("/home#faq")}>FAQ</li> */}

          <li onClick={() => navigate("/services")}>Services</li>
          <li onClick={() => navigate("/immunization")}>Immunization</li>
          <li onClick={() => navigate("/community")}>Community</li>
        </ul>
        <span className="footer__body__socials">
          <FaFacebook />
          <FaTwitter />
          <FaInstagram />
          <FaLinkedin />
        </span>
      </div>
      <div className="footer__foot">
        <p>
          ©2024 Serum Health, Inc. The professional health services made
          available through Serum Health, Inc. are provided by licensed
          clinicians affiliated with independently owned and operated
          professional practices, including Serum Clinical Associates and other
          contracted practices. Serum Health, Inc. provides administrative,
          technology, and other related services to the professional practices
          it supports..
        </p>
        <span>
          <p>Policy</p>
          <p>|</p>
          <p>Terms and service</p>
          <p>|</p>
          <p>Accessibility</p>
        </span>
      </div>
    </div>
  );
}

export default Footer;
