import "./app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Immunization from "./pages/immunization/Immunization";
import Community from "./pages/community/Community";
import About from "./pages/about/About";
import ScrollToTop from "./utils/ScrollToTop";
import Services from "./pages/services/Services";
import Service from "./pages/service/Service";

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/immunization" element={<Immunization />} />
          <Route path="/community" element={<Community />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service" element={<Service />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
